import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Bold = ({ children }) => <span className="font-semibold">{children}</span>
const Text = ({ children }) => <p className="md:text-xl text-lg align-center mb-5 ">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].marks[0]?.type === 'code'  ) {
        return <div className='relative flex flex-col'><div className='flex flex-grow flex-col'><div className='flex flex-col items-start  whitespace-pre-wrap'><div className='markdown prose w-full break-words dark:prose-invert dark'><pre><div className='bg-black mb-4 rounded-md'><div className='p-4 overflow-y-auto'><code className='!whitespace-pre hljs language-css'>{node.content[0].value}</code></div></div></pre></div></div></div></div>;
      }
    
      return <Text>{children}</Text>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
        // Extract videoId from the URL
        const match = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
          node.data.uri
      )
      const videoId =
          match && match[7].length === 11 ? match[7] : null
        if (node.data.uri.indexOf('youtube.com') !== -1) {
          return (
            <section className="video-container">
             <iframe
                  className="video"
                  title={`https://youtube.com/embed/${videoId}`}
                  src={`https://youtube.com/embed/${videoId}`}
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              />
            </section>
          );
        } 
        else{
          return (
            <a href={uri} className="underline text-blue-700">
              {children}
            </a>
          )
        }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const url = node?.data?.target?.file?.url
      const alt = node?.data?.target?.title
      if(node.data.target.file.url){
          return <img className='mb-5' alt={alt} src={url} />
      }
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className='text-2xl mt-10 mb-3 font-semibold'>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className='text-xl mt-10 mb-3 font-semibold'>{children}</h3>
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className='list-decimal pl-4'>{children}</ol>
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className='pl-5 list-disc text-md mb-5'>{children}</ul>
    },
    [BLOCKS.CODE]: (node, children) => {
      return <code className='border-2'>{children}</code>
    },
  },
}


const Project = ({data}) => {
    const {title, content, featuredImage, projectUrl} = data.contentfulProjects
    const image = getImage(featuredImage)

  return (
    <Layout>
    <Seo title={title} imageUrl={featuredImage?.url}/>
    <div className="w-full  max-w-screen-md mx-auto md:px-8 p-4">
      <div className='gap-8 md:py-10 py-3'>
        <div className='mb-10'>
            <GatsbyImage className='rounded-md overflow-hidden block shadow-2xl' image={image} alt={title} />
        </div>
            <div className=''>
                <h2 className="md:text-2xl text-xl font-semibold mt-1 mb-4">{title}</h2>
                {renderRichText(content, options)}
            </div>
            <div className='inline-flex'>
                <a className='text-lg font-semibold underline underline-offset-4 hover:no-underline' rel="noreferrer" href={projectUrl} target="_blank">Visit Website</a>
            </div>
        </div>
    </div>
    </Layout>
  )
}

export const singleprojectsgquery = graphql`
query getSingleProjects($title: String) {
    contentfulProjects(title: {eq: $title}) {
    id
    projectUrl
    content {
      raw
    }
    featuredImage {
        gatsbyImageData
        title
        url
    }
    title
  }
}
`

export default Project


